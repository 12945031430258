// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch
} from '../../../common/edliy_utils';
const Boxes = {
    box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
	var board1 = JXG.JSXGraph.initBoard('jxgbox1', {boundingbox: [-7, 12, 10, -5],
    keepaspectratio: true, axis:false, grid:true, ticks:false, showCopyright:false,
    showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
//Platform
	board1.options.layer['image'] =14;
	//board1.options.layer['segment'] =14;
	board1.suspendUpdate();
  makeResponsive(board1);
  // Creat titleStyle
  placeTitle(board1, 'First Law of Thermodynamics', '(Add or Remove Heat from the System)');

	board1.create('polygon',[[-9,-10],[20,-10],[20,-4],[-9, -4.]], {fillColor:'grey', fillOpacity:1, withLines:false, vertices:{visible:false}});
	//flask
	board1.create('polygon',[[0,-4.],[1,-4.],[1,5],[0, 5]], {fillColor:'black', fillOpacity:1, withLines:false, vertices:{visible:false}});
  board1.create('polygon',[[5,-4.],[6,-4.],[6,5],[5, 5]], {fillColor:'black', fillOpacity:1, withLines:false, vertices:{visible:false}});
  board1.create('polygon',[[0,-4.],[6,-4.],[6,-3],[0, -3]], {fillColor:'black', fillOpacity:1, withLines:false, vertices:{visible:false}});
  //Interior
	//board1.create('polygon',[[1,-3.],[5,-3.],[5,5.01],[1, 5.01]], {fillColor:'oldLace', withLines:false, fillOpacity:1, vertices:{visible:false}, borders:{visible:false}});
	//line
	var line = board1.create('line', [[3,0], [3, 10]], {visible: false, straightFirst: false, straightLast: false});
	//
	//var inp =board1.create('input', [6., 8, '1', ''],{cssStyle:'width:50px;fontFamily:Oswald;background-color:#008CBA;border: 1px solid black;border-radius: 3.5px;',fontSize:function(){return 20*board1.canvasHeight/800}, fixed:true});
	//
   var inp =placeSliderSwitch(board1, 6, 8, 1, 2, 1, '');
   inp.label.setAttribute({visible:false});
   board1.create('text', [7.5, 8, ()=>'&eta; = ' + (inp.Value()).toFixed(0)],{anchorX:'left', anchorY:'middle',fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', highlight:false, fixed:true});
  //var inp=1;
  //var diatomic = board1.create('checkbox', [6, 9, ' Diatomic'], {size:6, color:'black',fontSize:function(){return Math.round(18*board1.canvasWidth/500.)},CssStyle:'fontFamily:Oswald;fontWeight:bold'});
  //var monatomic = board1.create('checkbox', [6, 8, ' Monatomic'], {size:6, color:'black',fontSize:function(){return Math.round(18*board1.canvasWidth/500.)},CssStyle:'fontFamily:Oswald;fontWeight:bold'});
	//board1.create('text', [5., 8.,'&eta; = '],{CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*board1.canvasWidth/500.)},highlight:false, fixed:true});
	//
  //board1.create('slider', [[0, -2],[4, -2],[5, 8, 11.5]], {baseline:{strokeWidth:7, strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black'}, name:'Launch Speed',size:8,face:'square', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return 18*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

	board1.create('text', [7, 9., 'Monatomic Gas'],{anchorX:'middle', anchorY:'middle', display:'internal', fixed:true,  highlight:false,  CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(20*board1.canvasWidth/800.)}, visible:function(){if(inp.Value()==1){return true}else{return false}}});
	board1.create('text', [7, 9, 'Diatomic Gas'],{anchorX:'middle', anchorY:'middle', display:'internal', fixed:true, highlight:false, CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(20*board1.canvasWidth/800.)}, visible:function(){if(inp.Value()==2){return true}else{return false}}});
//thermometer
	var Q=0; var m =5; var g = 9.8; var R = 8.314; var Cv = function(){return (2*inp.Value()+3)*R.valueOf()/2}; var To = 25; var P = m*9.8/1.0; var i=0;
	var delt = function(){return Q.valueOf()/(Cv()+R);}
//height
	var delh = function(){return R*delt/P};
  board1.create('text', [-1, 6., function(){return '&Delta;U /&Delta;W = &eta; + 3/2 =' + (2*inp.Value()+3).toFixed(0) + '/2'}], {fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',highlight:false, fixed:true});

//Temp_inside
	board1.create('text', [2.0, -2., function(){return 'T_{In} =' + (To+ Q.valueOf()/(Cv()+R)).toFixed(2) + '^oC'}], {fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',highlight:false, fixed:true});
//Temp_outside
	board1.create('text', [7., 0.0, function(){return '&Delta;Q = '+ (Q).toFixed(0)+' J'}],
  {fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',highlight:false, fixed:true});
//block
	var up =board1.create('image', ['/assets/plus.svg', [7.5-0.75, -2.25], [1.5,1.5]],{fixed:true});
//
  hoverMe(board1, up, 'Tap to Add Heat', 0, -20);
	var down =board1.create('image', ['/assets/minuss.svg', [7.5+0.75, -2.25], [1.5,1.5]],{fixed:true});
  hoverMe(board1, down, 'Tap to Remove Heat', 0, -20);
  var run = function(){
		if(Q<500){
			Q+=100;}}
	var dec = function(){
		if(Q >-500){
		Q-=100;}}
	var mass = board1.create('glider', [3, 3, line], {name: '', size:0, face:'square', label:{offset:[-20, 0]}});
//tape
  inp.on('down', function(){mass.moveTo([3, 3]); Q=0;});
	up.on('down', function(){i=1; run(); mass.moveTo([3, 3.0+R*Q.valueOf()/(Cv()+R)/P],1000)});
	down.on('down', function(){i=-1;dec();mass.moveTo([3, 3.0+R*Q.valueOf()/(Cv()+R)/P],1000)});
	board1.create('image', ['/assets/weight.svg', [1.1, function(){return mass.Y()-1;}], [0.75, 0.75]],{highlight:false, fixed:true});
	board1.create('image', ['/assets/weight.svg', [1.85, function(){return mass.Y()-1;}], [0.75,0.75]],{highlight:false, fixed:true});
	board1.create('image', ['/assets/weight.svg', [2.6, function(){return mass.Y()-1;}], [0.75,0.75]],{highlight:false, fixed:true});
	board1.create('image', ['/assets/weight.svg', [3.35, function(){return mass.Y()-1;}], [0.75,0.75]],{highlight:false, fixed:true});
	board1.create('image', ['/assets/weight.svg', [4.1, function(){return mass.Y()-1;}], [0.75,0.75]],{highlight:false, fixed:true});
//
	board1.create('tapemeasure', [[-3, -4], [-3, function(){return mass.Y()-1.4}]], {name:'h2 (m)', withLabel: true, label:{highlight:false, anchorX:'middle', anchorY:'top', display:'internal' , rotate:90, fontSize:16,cssStyle:'fontFamily:Oswald;'}, fixed:true, point1:{fixed:true, size:4,face:'square', highlight:false}, point2:{highlight:false, fixed:true, size:4,face:'square'}});
	board1.create('tapemeasure', [[-1., -4], [-1., 3.0+R*Q/(Cv()+R)/P-1.4]], {name:'h1 (m)', withLabel: true, ticks:false,label:{highlight:false, anchorX:'middle', anchorY:'top', display:'internal' , rotate:90, fontSize:16,cssStyle:'fontFamily:Oswald;'}, fixed:true, point1:{size:4,face:'square', fixed:true, highlight:false}, point2:{highlight:false, size:4,face:'square', fixed:true}});
	board1.create('segment', [[-1, 3.0+R*Q/(Cv()+R)/P-1.4],[5., 3.0+R*Q/(Cv()+R)/P-1.4]],{fixed:true, highlight:false, dash:1, strokeWidth:1, strokeColor:'black'});
//Piston
	board1.create('polygon',[[1, function(){return mass.Y()-1.4;}],[5,function(){return mass.Y()-1.4;}],[5,function(){return mass.Y()-0.95;}],[1, function(){return mass.Y()-0.95;}]], {fillColor:'black', withLines:false, fillOpacity:1, vertices:{visible:false}});
//gas
   board1.create('segment', [[-3, function(){return mass.Y()-1.4;}],[5., function(){return mass.Y()-1.4;}]],{highlight:false, dash:1, strokeWidth:1, strokeColor:'black'});

	board1.create('polygon',[[1,-3.],[5,-3.],[5,function(){return mass.Y()-1.4;}],[1, function(){return mass.Y()-1.4;}]], {fillColor:'blue', withLines:false, fillOpacity:0.2, vertices:{visible:false}});
  board1.create('text', [7, 7,'R= 8.314 J/mol/K'], {display:'internal', anchorX:'middle', anchorY:'middle', highlight:false, fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});

  board1.create('text', [7, 6, function(){return 'C_p = '+ (3+2*inp.Value())+'R/2'}], {anchorY:'middle', anchorX:'middle',highlight:false, fixed:true, fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});
//
	board1.create('text', [-6.0, 9.0, 'Total Heat to System'], {highlight:false, fixed:true, fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});
//
  board1.create('text', [-1.0, 9.0, function(){return '&Delta;Q = '+ (Q).toFixed(2) + ' J'}], {anchorX:'left', highlight:false, fixed:true, highlight:false, fixed:true, fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});
//
	board1.create('text', [-6.0, 8.0, 'Work Done by the System'], {highlight:false, fixed:true, fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});
//
   board1.create('text', [-1.0, 8.0, function(){return '&Delta;W = mg&Delta;h = '+ (m*g*R*Q.valueOf()/(Cv()+R)/P).toFixed(2) + ' J'}], {anchorX:'left', highlight:false, fixed:true, fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;',fixed:true});

	board1.create('text', [-6.0, 7.0, 'Change in Internal Energy'], {highlight:false, fixed:true, fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', fixed:true});
//
board1.create('text', [-1.0, 7.0, function(){return '&Delta;U = C_p&Delta;T = '+ (Cv()*Q.valueOf()/(Cv()+R)).toFixed(2) + ' J'}], {anchorX:'left', highlight:false, fixed:true, fontSize:function(){return 20*board1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;', fixed:true});

	board1.create('image', ['/assets/ac.svg', [1, -5.], [1,1]],
  {highlight:false, fixed:true,
  visible:function(){if(mass.Y()>3.0+R*Q.valueOf()/(Cv()+R)/P){return true} else{return false}}});
	board1.create('image', ['/assets/ac.svg', [2.5, -5.], [1,1]], {highlight:false, fixed:true, visible:function(){if(mass.Y()>3.0+R*Q.valueOf()/(Cv()+R)/P){return true} else{return false}}});
	board1.create('image', ['/assets/ac.svg', [4., -5.], [1,1]], {highlight:false, fixed:true, visible:function(){if(mass.Y()>3.0+R*Q.valueOf()/(Cv()+R)/P){return true} else{return false}}});
	board1.create('image', ['/assets/flame.svg', [1., -5], [1,1]], {highlight:false, fixed:true, visible:function(){if(mass.Y()<3.0+R*Q.valueOf()/(Cv()+R)/P){return true} else{return false}}});
	board1.create('image', ['/assets/flame.svg', [2.5, -5], [1,1]], {highlight:false, fixed:true, visible:function(){if(mass.Y()<3.0+R*Q.valueOf()/(Cv()+R)/P){return true} else{return false}}});
	board1.create('image', ['/assets/flame.svg', [4., -5], [1,1]], {highlight:false, fixed:true, visible:function(){if(mass.Y()<3.0+R*Q.valueOf()/(Cv()+R)/P){return true} else{return false}}});
	board1.unsuspendUpdate();
	}
}
export default Boxes;
