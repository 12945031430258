<template>
  <div>
    <h3 ref="ia">
      Heat & Work: Modes of Energy Transfer
    </h3>
    <p>
      A thermodynamic system can exchange energy with its
      surroundings in two ways: either through transfer of heat or by doing work on its
      surroundings. In other words,
      work and heat are the forms of energy that can be transferred across a system's boundary.
    </p>
    <ul class="a">
      <li>
        <h5> Heat</h5>
        Heat is defined as the form of energy that is transferred between two systems
        due to a difference in their temperatures. 
        There cannot be any heat transfer between two systems that are at the same temperature.
        Heat is measured in units of Joules in the SI system (or calories in the FPS system).
        <br><br>
        In thermodynamics, we use a
        sign convention to indicate whether heat is entering the system or
        leaving the system.<br>
        <ul>
          <li>
            When the heat is transferred from the surroundings to the system,
            it is considered positive. <br>
          </li>
          <li>
            When the heat is transferred
            from the system to the surroundings, it is considered negative.
          </li>
        </ul>
      </li>
      <li>
        <h5> Work</h5>
        Work is the energy transfer that occurs through a mechanical interaction
        between a system and its surroundings.
        More specifically, work is the energy transfer associated with the displacement (expansion/contraction) of the system boundary under the action of a force (or equivalently, change in the system's volume under the action of pressure).
        <br><br>
        In thermodynamics, we use a
        sign convention to indicate whether the work is done by the system or on the system. <br>
        <ul>
          <li>
            If the system's volume expands under the action of the force,
            then we say work is done by the system and it is considered positive.
          </li>
          <li>
            On the other hand, if the system's volume contracts due to the action of the force, then we say work is done on the system and it is considered negative.
          </li>
        </ul>
      </li>
    </ul>
    <br>
    <!--<h3 ref="laws"> Laws of Thermodynamics</h3>
<p> The interaction between a thermodynamic system and its surroundings is governed by a set of three physical laws. These are known as laws of thermodynamics. </p>
<br>
<h3 ref="zeroth"> Zeroth Law of Thermodynamics</h3>
<p> The zeroth law of thermodynamics helps us determine if there is going to be a heat transfer between two thermodynamic systems or not. This law essentially says that every thermodynamic system can be assigned a state variable (or a 'tag') called temperature. If two systems happen to have the same tag (i.e. temperature), then no heat transfer will take place between those systems, and they are said to be in thermal equilibrium with each other. However, if the two systems happen to have different tags (i.e. are at different temperatures), then there will be a heat transfer from one system to the other when they are brought in thermal contact.
</p>
<br> -->
    <h3 ref="first">
      First Law of Thermodynamics
    </h3>
    <p>
      When a thermodynamic system exchanges energy with its surroundings
      either in the form of heat or by performing work on its surroundings,
      the system's internal energy is changed.
      The first law of thermodynamics is the law that relates the amount of heat transferred and work done with the resultant change in internal energy. <br>
      <br>
      The first law of thermodynamics, in essence, is a statement of conservation of energy. It states that the internal energy of a system can be changed in two ways &mdash; by transferring heat to or from the system or working on the system. First law of thermodyanmics says that the change in internal energy of a system is equal to the heat transferred to system minus the work done by the system.
      $$\Delta U = \Delta Q - \Delta W$$
      where \(\Delta Q\) is the supplied heat, \(\Delta U\) is the change in the internal energy of the system, and \(\Delta W\) is the change in the work done by the system
    </p>
    <h3 ref="pg">
      MagicGraph: Ideal Gas in Cylinder-Piston System
    </h3>
    <p>
      Here, we consider an ideal gas contained in a cylinder-piston assembly.
      All surfaces are assumed to be completely frictionless.
      The equation of state for the gas can be given as:
      $$P V = n R T$$
      where \(P\) is the pressure, \(V\) is the volume, \(n\) is the number of moles,
      \(R\) is the universal gas constant, and \(T\) is the temperature. <br>
      Heat can be supplied to the system by means of a heater.
      Similarly, heat can be removed by means of a cooler.
      Part of the supplied heat goes into changing the internal energy of the system
      (by changing the temperature) and the remaining is spent in the form of work done by the system on its surroundings.
      <br>
      The change in internal energy is given as &mdash;
      $$\Delta U = \int n C_p dT = n C_p \Delta T$$
      where \(C_p\) is the molar specific heat of the gas at constant pressure. It is generally given as:
      $$C_p = (2\eta+3)R/2$$
      where \(\eta\) is the atomicity. For monoatomic gases (\(\eta =1\)), \(C_p\) is given as
      $$C_p = \frac{5}{2}R$$
      while for diatomic gas (\(\eta =2\)), \(C_p\) is given as
      $$C_p = \frac{7}{2}R$$
      The work done by the system on its surroundings is given as &mdash;
      $$\Delta W = \int P dV = n R \Delta T$$
      Substituting in the first law of thermodynamics gives
      $$\Delta Q = (n C_p + n R)\Delta T$$
    </p>
    <ul class="a">
      <li>
        <h5>To Get Started </h5>
        <ul style="list-style-type:circle;">
          <li>Choose the type of gas that you would like to study: monoatomic (&eta; = 1) or diatomic (&eta;=2).</li>
          <li>Tap on the <i class="fa fa-plus-square" /> button to add heat to the system. Tap on the <i class="fa fa-minus-square" /> button to remove heat from the system.</li>
        </ul>
      </li>
      <li>
        <h5>To Explore </h5>
        <ul style="list-style-type: circle;">
          <li>Observe how the internal energy and the work done by the system change as you add or remove heat.</li>
          <li>Calculate the ratio of the change in internal energy to the work done by the system. Note how this ratio evolves as you add or remove heat. </li>
          <li>Observe how this ratio changes as you change the gas from monoatomic to diatomic. </li>
        </ul>
      </li>
    </ul>
    <br> <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FirstLawOfThermodynamics',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'First Law of Thermodynamics';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Heat & Work', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'First Law of Thermodynamics', img:'/assets/number-2.svg', action: () => this.goto('first')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
    },
    mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'First Law of Thermodynamics',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Laws of Thermodynamics'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
